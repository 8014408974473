import React from "react";
import { Link } from "react-router-dom";

const numberFormatter = Intl.NumberFormat("en-US");

const Minter = (props) => {
  const {
    maxSupply,
    totalSupply,
    mintAmount,
    setMintAmount,
    maxAmount,
    mintedAmount,
    state,
    handleMint,
  } = props;

  const handleDecrement = () => {
    const newValue = mintAmount - 1;
    if (newValue > 0) {
      setMintAmount(newValue);
    }
  };

  const handleIncrement = () => {
    const newValue = mintAmount + 1;
    if (
      newValue <= Math.min(maxAmount - mintedAmount, maxSupply - totalSupply)
    ) {
      setMintAmount(newValue);
    }
  };

  return (
    <div className="bloc-minter">
      <div className="h7 count-minter">
        {numberFormatter.format(totalSupply)}/
        {numberFormatter.format(maxSupply)} Total Minted
      </div>
      {state === "disabled" ? (
        <p>
          <em>
            Minting is not available right now.
            <br />
            Come back later
          </em>
        </p>
      ) : state === "soldout" ? (
        <div className="tf-button-soldout">Sold Out </div>
      ) : state === "not-whitelisted" ? (
        <p>
          <em>This wallet is not in the whitelist.</em>
        </p>
      ) : (
        <div className="wrap-minter">
          <div className="left">
            <div className="controls">
              <div className="minter-control" onClick={handleDecrement}>
                -
              </div>
              <input readOnly value={numberFormatter.format(mintAmount)} />
              <div className="minter-control" onClick={handleIncrement}>
                +
              </div>
            </div>
            <div className="info-limit">
              You have minted {numberFormatter.format(mintedAmount)}/
              {numberFormatter.format(maxAmount)} NFTs
            </div>
          </div>
          <Link
            to="#"
            className="tf-button-st2-header btn-mint btn-effect"
            onClick={handleMint}
          >
            <span className="effect">Mint </span>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Minter;
