import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

Partner.propTypes = {
  data: PropTypes.array,
};

function Partner(props) {
  const { data } = props;

  return (
    <section className="tf-section partner">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="tf-title"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <p className="h8 sub-title">partnerS</p>
              <h4 className="title title-partner">SOCCER BOYZ PARTNERS</h4>
            </div>
          </div>
          <div className="col-md-12">
            <div
              className="item-partner"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              {data.slice(0, 4).map((item, idx) => (
                <div key={idx} className="image">
                  <img
                    src={item.img}
                    className="img-partner"
                    alt="Soccer Boyz Partner"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Partner;
