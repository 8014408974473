import bgImg from "../images/background/home-main.jpg";

const dataSlider = [
  {
    id: 1,
    title: "Soccer Boyz",
    desc: "P2E Football Management Game for Crypto Natives",
    bgImg: bgImg,
    classAction: "three",
  },
];

export default dataSlider;
