import img1 from "../images/logo/logo_polygon.png";
import img2 from "../images/logo/logo_opensea.png";
import img3 from "../images/logo/logo_axp3_v2.png";
import img4 from "../images/logo/logo_opta.png";
const dataPartner = [
  {
    img: img1,
  },
  {
    img: img2,
  },
  {
    img: img3,
  },
  {
    img: img4,
  },
];

export default dataPartner;
