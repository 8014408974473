import React from "react";

import PageTitle from "../components/pagetitle";
import About from "../features/about/home-v2";

function AboutOne() {
  return (
    <div className="about">
      <PageTitle title="About" />

      <About />
    </div>
  );
}

export default AboutOne;
