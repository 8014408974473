const dataAbout = [
  {
    id: 1,
    title: "4,444 Unique Players",
    desc: "The Soccer Boyz NFT collection is made of 4,444 unique football players with their own traits and stats, that can be used in-game. ",
    class: "active",
  },
  {
    id: 2,
    title: "P2E Football Management Game",
    desc: "The next gen of football simulation. Build and train your team to reach the top of the weekly league and be rewarded with crypto.",
  },
  {
    id: 3,
    title: "Real-life rewards",
    desc: "Hold and stake your NFTs to be eligible for exclusive real-life rewards including tickets to see your favorite teams, merch and much more.",
  },
];

export default dataAbout;
