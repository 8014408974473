import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "../styles.scss";
import Button from "../../../components/button";

import logo from "../../../assets/images/logo/logo_axp3_big.png";

About.propTypes = {
  data: PropTypes.array,
};

function About() {
  const [dataBlock] = useState({
    subtitle: "About Soccer Boyz",
    title: "The Creators of Soccer Boyz",
  });

  const onClick = (e) => {
    window.location.href = "mailto:hello@axp3.studio";
    e.preventDefault();
  };

  return (
    <section className="tf-section tf-about style2">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="content-about"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <div className="tf-title">
                <p className="h8 sub-title">{dataBlock.subtitle}</p>
                <h4 className="title">{dataBlock.title}</h4>
              </div>
              <div className="about__logo">
                <Link to="#">
                  <img src={logo} alt="axp3 logo" />
                </Link>
              </div>
              <p className="pd20">
                Soccer Boyz is a project created by the <b>AXP3 Studio</b>.
              </p>
              <p>
                AXP3 is a Web3 Studio with extensive expertise in all
                Web3-related developments: Smart Contracts, DApps, NFT
                Collections, Fullstack Dev, and much more.
              </p>
              <p className="desc-accent">
                Want to start your own project? Drop us an email by clicking on
                the button below.
              </p>
            </div>
          </div>

          <div className="col-md-12">
            <div
              className="btn-about"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <Button title="Contact AXP3" path="#" onClick={onClick} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
