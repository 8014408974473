import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import NFTConfig from "../../config/NFTConfig";
import { getProofForAddress, contains } from "../../scripts/lib/whitelist.js";
import detectEthereumProvider from "@metamask/detect-provider";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import Minter from "../../components/minter";
import "./styles.scss";

import imgMint from "../../assets/images/mint/main_art.png";
import logo from "../../assets/images/logo/logo_v2@2x.png";

const ContractAbi = require("../../config/" +
  NFTConfig.contractName +
  ".json").abi;

function Mint() {
  const chainId = useRef(null);
  const contract = useRef(null);
  const provider = useRef(null);

  const [mintAmount, setMintAmount] = useState(1);
  const [mintedAmount, setMintedAmount] = useState(0);
  const [account, setAccount] = useState(null);
  const [network, setNetwork] = useState(null);
  const [isWhitelistMintEnabled, setIsWhitelistMintEnabled] = useState(false);
  const [isPublicMintEnabled, setIsPublicMintEnabled] = useState(false);
  const [isUserInWhitelist, setIsUserInWhitelist] = useState(false);
  const [totalSupply, setTotalSupply] = useState(0);
  const [maxSupply, setMaxSupply] = useState(4444);
  const [maxAmount, setMaxAmount] = useState(1);
  const [errorMessage, setErrorMessage] = useState(null);

  const soldOut = maxSupply <= totalSupply;
  // Set this to true to set the collection as sold out manually
  const manualSoldOut = true;

  useEffect(() => {
    getProvider();
  }, []);

  const getProvider = async () => {
    const browserProvider = await detectEthereumProvider();
    if (browserProvider) {
      const _provider = new ethers.providers.Web3Provider(browserProvider);
      registerWalletEvents(browserProvider);
      provider.current = _provider;
      const _network = await provider.current.getNetwork();
      chainId.current = _network.chainId;
      setNetwork(_network);
      if (_network.chainId === NFTConfig.network.chainId) {
        contract.current = new ethers.Contract(
          NFTConfig.contractAddress,
          ContractAbi,
          _provider.getSigner()
        );
      }
      await initWallet();
    }
  };

  const updateContract = async (_account) => {
    if (contract.current) {
      setIsWhitelistMintEnabled(
        await contract.current.isWhitelistMintEnabled()
      );
      setIsPublicMintEnabled(await contract.current.isPublicMintEnabled());
      const _maxSupply = (await contract.current.maxSupply()).toNumber();
      setMaxSupply(_maxSupply);
      const _totalSupply = (await contract.current.totalSupply()).toNumber();
      setTotalSupply(_totalSupply);
      const _maxAmount = (await contract.current.maxPerWallet()).toNumber();
      setMaxAmount(_maxAmount);
      const _mintedAmount = (
        await contract.current.numberMinted(_account)
      ).toNumber();
      setMintedAmount(_mintedAmount);
      setMintAmount(
        Math.min(_maxSupply - _totalSupply, _maxAmount - _mintedAmount)
      );
    }
  };

  const initWallet = async () => {
    if (provider.current) {
      const walletAccounts = await provider.current.listAccounts();
      if (walletAccounts.length === 0) {
        setAccount(null);
        setIsUserInWhitelist(false);
        setMintedAmount(0);
      } else {
        setAccount(walletAccounts[0]);
        setIsUserInWhitelist(contains(walletAccounts[0]));
        await updateContract(walletAccounts[0]);
      }
    } else {
      setAccount(null);
      setIsUserInWhitelist(false);
      setMintedAmount(0);
    }
  };

  const connectWallet = async () => {
    setErrorMessage(null);
    try {
      await provider.current.provider.request({
        method: "eth_requestAccounts",
      });
      setNetwork(await provider.current.getNetwork());
    } catch (e) {
      setErrorMessage("An error has occurred while connecting your wallet.");
    }
    await initWallet();
  };

  const changeNetwork = async () => {
    setErrorMessage(null);
    try {
      await provider.current.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + NFTConfig.network.chainId.toString(16) }],
      });
    } catch (e) {
      if (e.code === 4902) {
        try {
          await provider.current.provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x" + NFTConfig.network.chainId.toString(16),
                chainName: NFTConfig.network.name,
                rpcUrls: NFTConfig.network.rpcUrls,
              },
            ],
          });
        } catch {
          setErrorMessage("An error has occurred while adding the network.");
        }
      } else {
        setErrorMessage("An error has occurred while switching the network.");
      }
    }
  };

  const publicMint = async () => {
    setErrorMessage(null);
    try {
      const transaction = await contract.current.publicMint(mintAmount);
      await endMintTransaction(transaction);
    } catch (e) {
      if (e.error && e.error.data && e.error.data.message) {
        setErrorMessage(e.error.data.message);
      } else {
        setErrorMessage("Transaction failed. An error has occurred");
      }
    }
  };

  const whitelistMint = async () => {
    setErrorMessage(null);
    try {
      const transaction = await contract.current.whitelistMint(
        mintAmount,
        getProofForAddress(account)
      );
      await endMintTransaction(transaction);
    } catch (e) {
      if (e.error && e.error.data && e.error.data.message) {
        setErrorMessage(e.error.data.message);
      } else {
        setErrorMessage("Transaction failed. An error has occurred");
      }
    }
  };

  const endMintTransaction = async (transaction) => {
    toast.info(
      <>
        Transaction sent! Please wait...
        <br />
        <a
          href={NFTConfig.network.blockExplorer.generateTransactionUrl(
            transaction.hash
          )}
          target="_blank"
          rel="noopener noreferrer"
        >
          View on {NFTConfig.network.blockExplorer.name}
        </a>
      </>
    );
    const receipt = await transaction.wait();
    toast.dismiss();
    toast.success(
      <>
        Success!
        <br />
        <a
          href={NFTConfig.network.blockExplorer.generateTransactionUrl(
            receipt.transactionHash
          )}
          target="_blank"
          rel="noopener noreferrer"
        >
          View on {NFTConfig.network.blockExplorer.name}
        </a>
      </>
    );
    await updateContract(account);
  };

  const registerWalletEvents = (browserProvider) => {
    browserProvider.on("accountsChanged", () => {
      initWallet();
    });

    browserProvider.on("chainChanged", (arg) => {
      if (provider.current !== null) {
        if (chainId.current !== arg) {
          window.location.reload();
        }
      }
    });
  };

  return (
    <section className="tf-section tf-mint style2">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="content-mint"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              {errorMessage ? (
                <div className="error">
                  {errorMessage}
                  <span
                    className="button"
                    onClick={() => setErrorMessage(null)}
                  >
                    &times;
                  </span>
                </div>
              ) : null}
              <div className="main-mint-bloc">
                <div className="img-mint">
                  <img src={imgMint} alt="mint img" />
                </div>
                <div className="bloc-mint">
                  <img src={logo} alt="soccer boyz logo" />
                  <p
                    className={
                      "info-network " + (!network && "network-warning")
                    }
                  >
                    {soldOut || manualSoldOut || !network
                      ? ""
                      : network.chainId === NFTConfig.network.chainId
                      ? "Currently connected to the " +
                        NFTConfig.network.name +
                        " network."
                      : "Please connect to the correct network: " +
                        NFTConfig.network.name +
                        "."}
                  </p>
                  {manualSoldOut ? ( // If we have manually set the collection as sold out
                    <>
                      <Minter
                        maxSupply={maxSupply}
                        totalSupply={maxSupply}
                        state="soldout"
                      />
                      <p className="manualsoldout-txt">
                        All NFTs have been minted. Buy one{" "}
                        <a
                          href="https://opensea.io/collection/soccer-boyz"
                          className="buy-url"
                        >
                          here
                        </a>
                        .
                      </p>
                    </>
                  ) : network ? (
                    soldOut ? (
                      <Minter
                        maxSupply={maxSupply}
                        totalSupply={totalSupply}
                        state="soldout"
                      />
                    ) : account ? (
                      network.chainId === NFTConfig.network.chainId ? (
                        !isWhitelistMintEnabled && !isPublicMintEnabled ? (
                          <Minter
                            maxSupply={maxSupply}
                            totalSupply={totalSupply}
                            state="disabled"
                          />
                        ) : isPublicMintEnabled ? (
                          <Minter
                            maxSupply={maxSupply}
                            totalSupply={totalSupply}
                            mintAmount={mintAmount}
                            setMintAmount={setMintAmount}
                            maxAmount={maxAmount}
                            mintedAmount={mintedAmount}
                            handleMint={publicMint}
                            state="public"
                          />
                        ) : isUserInWhitelist ? (
                          <Minter
                            maxSupply={maxSupply}
                            totalSupply={totalSupply}
                            mintAmount={mintAmount}
                            setMintAmount={setMintAmount}
                            maxAmount={maxAmount}
                            mintedAmount={mintedAmount}
                            handleMint={whitelistMint}
                            state="whitelisted"
                          />
                        ) : (
                          <Minter
                            maxSupply={maxSupply}
                            totalSupply={totalSupply}
                            state="not-whitelisted"
                          />
                        )
                      ) : (
                        <div className="bloc-not-connected">
                          <Link
                            to="#"
                            className="tf-button-st2-header btn-effect"
                            onClick={() => changeNetwork()}
                          >
                            <span className="effect">Change Network</span>
                          </Link>
                        </div>
                      )
                    ) : (
                      <div className="bloc-not-connected">
                        <p>
                          <em>Wallet not connected</em>
                        </p>
                        <Link
                          to="#"
                          className="tf-button-st2-header btn-effect"
                          onClick={() => connectWallet()}
                        >
                          <span className="effect">Connect Wallet</span>
                        </Link>
                      </div>
                    )
                  ) : (
                    <div className="bloc-no-wallet">
                      Please install Metamask to mint a NFT.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Mint;
