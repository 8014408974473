import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import AboutItem from "./about-item";

About.propTypes = {
  data: PropTypes.array,
};

function About(props) {
  const { data } = props;

  const [dataBlock] = useState({
    subtitle: "The Project",
    title: "What is Soccer Boyz?",
    desc: "Soccer Boyz is a new kind of football management game built around a collection of 4,444 unique digital collectibles on the Polygon Blockchain. Buy, Trade and Train your NFTs to build the best team and take part in weekly competitions to earn rewards, while having fun.",
  });

  return (
    <section className="tf-section tf-about">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-md-12">
            <div
              className="content-about mobie-40"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <div className="tf-title st2">
                <p className="h8 sub-title">{dataBlock.subtitle}</p>
                <h4 className="title">{dataBlock.title}</h4>
              </div>
              <p>{dataBlock.desc}</p>
              <a
                className="tf-button btn-effect"
                href="https://opensea.io/collection/soccer-boyz"
              >
                <span className="boder-fade"></span>
                <span className="effect">Buy a NFT</span>
              </a>
            </div>
          </div>
          <div className="col-xl-7 col-md-12">
            <div
              className="wrap-about"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              {data.map((item) => (
                <AboutItem key={item.id} item={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
