import React from "react";
import HomeOne from "./HomeOne";
import AboutOne from "./AboutOne";
import Page404 from "./404";
import MintPage from "./MintPage";

const routes = [
  { path: "/", component: <HomeOne /> },
  { path: "/about", component: <AboutOne /> },
  { path: "/mint", component: <MintPage /> },
  { path: "/404", component: <Page404 /> },
];

export default routes;
