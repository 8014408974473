import img1 from "../images/common/13.png";
import img2 from "../images/common/20.png";
import img3 from "../images/common/104.png";
import img4 from "../images/common/315.png";
import img5 from "../images/common/516.png";
import img6 from "../images/common/650.png";
import img7 from "../images/common/1278.png";
import img8 from "../images/common/1698.png";
import img9 from "../images/common/2266.png";
import img10 from "../images/common/2272.png";
import img11 from "../images/common/2581.png";
import img12 from "../images/common/2968.png";
import img13 from "../images/common/3282.png";
import img14 from "../images/common/3455.png";
import img15 from "../images/common/3919.png";
import img16 from "../images/common/3941.png";

const dataProject = [
  {
    id: 1,
    title: "Soccer Boyz #13",
    img: img1,
  },
  {
    id: 2,
    title: "Soccer Boyz #20",
    img: img2,
  },
  {
    id: 3,
    title: "Soccer Boyz #104",
    img: img3,
  },
  {
    id: 4,
    title: "Soccer Boyz #315",
    img: img4,
  },
  {
    id: 5,
    title: "Soccer Boyz #516",
    img: img5,
  },
  {
    id: 6,
    title: "Soccer Boyz #650",
    img: img6,
  },
  {
    id: 7,
    title: "Soccer Boyz #1278",
    img: img7,
  },
  {
    id: 8,
    title: "Soccer Boyz #1698",
    img: img8,
  },

  {
    id: 9,
    title: "Soccer Boyz #2266",
    img: img9,
  },
  {
    id: 10,
    title: "Soccer Boyz #2272",
    img: img10,
  },
  {
    id: 11,
    title: "Soccer Boyz #2581",
    img: img11,
  },
  {
    id: 12,
    title: "Soccer Boyz #2968",
    img: img12,
  },
  {
    id: 13,
    title: "Soccer Boyz #3282",
    img: img13,
  },
  {
    id: 14,
    title: "Soccer Boyz #3455",
    img: img14,
  },
  {
    id: 15,
    title: "Soccer Boyz #3919",
    img: img15,
  },
  {
    id: 16,
    title: "Soccer Boyz #3941",
    img: img16,
  },
];

export default dataProject;
