const menus = [
  {
    id: 1,
    name: "Home",
    links: "/",
  },
  {
    id: 2,
    name: "Mint",
    links: "/mint",
  },
  {
    id: 3,
    name: "About",
    links: "/about",
  },
];

export default menus;
