import React from "react";

import PageTitle from "../components/pagetitle";
import Mint from "../features/mint";

function MintPage() {
  return (
    <div className="mint">
      <PageTitle title="Mint a NFT" />
      <Mint />
    </div>
  );
}

export default MintPage;
