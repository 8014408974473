import { MerkleTree } from 'merkletreejs';
import keccak256 from 'keccak256';
import whitelistAddresses from "../../config/whitelist.json";

const merkleTree = new MerkleTree(whitelistAddresses.map(addr => keccak256(addr)), keccak256, { sortPairs: true });

export const getProofForAddress = (address) =>
{
  return merkleTree.getHexProof(keccak256(address));
}

 export const  getRawProofForAddress = (address) =>
{
  return getProofForAddress(address).toString().replaceAll('\'', '').replaceAll(' ', '');
}

export const contains = (address) =>
{
  return merkleTree.getLeafIndex(Buffer.from(keccak256(address))) >= 0;
}

