const NFTConfig = {
  contractName: "SoccerBoyz",
  contractAddress: "0xa5752af9D450adDF7fE12872a695848FAD32F8Ee",
  network: {
    chainId: 137,
    name: "Polygon",
    rpcUrls: ["https://polygon-rpc.com/"],
    blockExplorer: {
      name: "Block explorer (not available for local chains)",
      generateContractUrl: (contractAddress) =>
        `https://polygonscan.com/address/${contractAddress}`,
      generateTransactionUrl: (transactionAddress) =>
        `https://polygonscan.com/tx/${transactionAddress}`,
    },
  },
  marketplaceIdentifier: "soccerboyz-token",
  marketplaceConfig: "",
};

export default NFTConfig;
