const dataFaq = [
  {
    id: 1,
    title: "How big is the Collection?",
    content:
      "The Soccer Boyz NFT collection consists of 4,444 unique NFTs. Each NFT represents a football player with unique stats and traits (among more than 140).",
    show: "show",
  },
  {
    id: 2,
    title: "Which chain and contract are we using?",
    content:
      "The Soccer Boyz NFT collection is on the Polygon Blockchain, using a ERC-721A smart contract.",
  },
  {
    id: 3,
    title: "When is the Mint?",
    content:
      "The collection has already been sold out during the mint phase that happened on Sep 01, 2022.",
  },
  {
    id: 4,
    title: "What is the price of the Mint?",
    content: "This is a free mint project.",
  },
];

export default dataFaq;
