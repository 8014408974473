const dataRoadmap = [
  {
    id: 1,
    time: "Q3 2022",
    list: [
      {
        text: "Launch of the NFT Collection",
      },
      {
        text: "Creation of the Soccer Boyz Holders Club",
      },
      {
        text: "Release of Game Beta",
      },
    ],
    positon: "left",
    style: "normal",
  },
  {
    id: 2,
    time: "Q4 2022",
    list: [
      {
        text: "Transfer Market",
      },
      {
        text: "Weekly Tournaments",
      },
      {
        text: "NFT Staking",
      },
      {
        text: "Release of Game Alpha",
      },
    ],
    positon: "right",
    style: "normal",
  },
  {
    id: 3,
    time: "Q1 2023",
    list: [
      {
        text: "Weekly Leagues",
      },
      {
        text: "Merch Airdrops",
      },
      {
        text: "Release of Game Final Version",
      },
    ],
    positon: "left",
  },
  {
    id: 4,
    time: "Q2 2023",
    list: [
      {
        text: "Launch of the Stadiums and Coachs",
      },
      {
        text: "NFT Breeding",
      },
      {
        text: "And much more!",
      },
    ],
    positon: "right",
  },
];

export default dataRoadmap;
