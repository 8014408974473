import React from "react";

import "../scss/components/section.scss";
import "../scss/components/box.scss";
import Slider from "../components/slider";
import dataSlider from "../assets/data/data-slider";
import About from "../features/about";
import Project from "../features/project";
import dataProject from "../assets/data/dataProject";
import dataAbout from "../assets/data/data-about";
import RoadMap from "../features/roadmap";
import dataRoadmap from "../assets/data/data-roadmap";
import Partner from "../features/partner";
import dataPartner from "../assets/data/data-partner";
import FAQ from "../features/faq";
import dataFaq from "../assets/data/data-faq";

function HomeOne() {
  return (
    <div className="home-1">
      <Slider data={dataSlider} />

      <About data={dataAbout} />

      <Project data={dataProject} />

      <RoadMap data={dataRoadmap} />

      <Partner data={dataPartner} />

      <FAQ data={dataFaq} />
    </div>
  );
}

export default HomeOne;
